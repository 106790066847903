<template>
  <div>
    <b-alert :show="dataSynchronized == true && !hideMessageWhenSync" variant="info">
      <b-icon icon="cloud-check-fill" variant="success" />&nbsp;Les données sont synchronisées.
      <info-helper>
        <p>Les données <slot name="data-description"><b>{{data}}&nbsp;</b></slot>que vous visualiszez ou que vous vous apprêtez à modifier sont correctement synchronisées avec le serveur.</p>
        <p>Cela signifie que votre copie locale de celles-ci est à jour.</p>
      </info-helper>
    </b-alert>
    <b-alert :show="dataSynchronized == false && !hideMessageWhenNotSync" variant="warning">
      <b-icon icon="cloud-slash-fill" variant="danger" />&nbsp;Les données ne sont pas synchronisées.
      <info-helper>
        <p>Les données <slot name="data-description"><b>{{data}}&nbsp;</b></slot>que vous visualisez ou que vous vous apprêtez à modifier ne sont pas synchronisées avec le serveur.</p>
        <p>La connexion internet est probablement inexistante ou de mauvaise qualité si bien que les données présentées ici sont celles d'une copie locale
        et ne peuvent donc pas être garanties complètement exactes, un autre utilisateur ayant pu les modifier de son côté.</p>
      </info-helper>
      <div class="refresh-button">
        <i>cliquez sur ce bouton pour raffraichir maintenant</i>
        <b-button @click="refresh_click">
          <b-icon  icon="arrow-repeat" size="xl" variant="warning"/>
        </b-button>
      </div>
    </b-alert>
  </div>
</template>

<script>
import InfoHelper from '@/components/InfoHelper.vue';

export default{
  model:{
    prop: 'dataSynchronized'
  },
  components: { InfoHelper },
  props:{
    dataSynchronized: { type: Boolean, default: null },
    hideMessageWhenSync: { type: Boolean, default: false},
    hideMessageWhenNotSync: { type: Boolean, default: false},
    data: { type:String, default: ''},
  },
  methods:{
    refresh_click(){
      this.$emit('refresh');
    }
  }
}
</script>

<style scoped>
  .refresh-button {
    float:right;
    margin:-6px;
   /* width:2em !important;
    height:2em !important;*/
  }
  .refresh-button > i { font-size: 0.5em;}
</style>
